<template>
  <div class="mainwrap privacylist" v-loading="loading">
    <div class="title">
      推薦碼
    </div>
    <div class="searchform">
      <el-form
          label-width="82px"
          :inline="true"
          :model="searchForm"
          class="demo-form-inline"
      >

        <el-form-item label="會員名稱：">
          <el-input class="w_50" v-model="searchForm.lastname" placeholder="請輸入姓"></el-input>
          <el-input class="w_50" v-model="searchForm.name" placeholder="請輸入名"></el-input>
        </el-form-item>

        <el-form-item label="推薦碼：">
          <el-input
              v-model="searchForm.code"
              placeholder="請輸入推薦碼"
          ></el-input>
        </el-form-item>
        <el-form-item label="時間：">
          <el-date-picker
              v-model="searchForm.timeRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
          <el-button type="success" @click="downLoad" :disabled="disabledDown">導出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="id" label="id"> </el-table-column>
        <el-table-column prop="recommendCode" label="推薦碼"> </el-table-column>
        <el-table-column prop="memberName" label="會員名稱"> </el-table-column>
        <el-table-column prop="mobile" label="手機號碼"> </el-table-column>
        <el-table-column prop="creationTime" label="創建日期"> </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
          background
          :hide-on-single-page="isSinglePage"
          layout="prev, pager, next,jumper"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";

export default {
  name:'recommendationCode',
  data(){
    return{
      loading: false,
      isSinglePage: false,
      currentPage: 1,
      tableDataTotal: 0,
      searchForm:{
        name:"",
        lastname:"",
        timeRange:'',
        code:'',
        startTime:"",
        endTime:"",
        sorting: '',
        maxResultCount: 10
      },
      tableData: [],
      disabledDown:true
    }
  },
  methods:{
    reSet(){
      this.searchForm={
        name:"",
        timeRange:'',
        code:'',
        startTime:"",
        endTime:"",
        sorting: '',
        maxResultCount: 10
      }
      this.currentPage=1;
      this.getList();
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getList()
    },
    // 查詢
    onSearch() {
      this.currentPage=1;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.disabledDown=true;
      api.recommendList({
        Name: this.searchForm.name,
        LastName: this.searchForm.lastname,
        RecommendCode: this.searchForm.code,
        Startime: this.searchForm.timeRange[0]?this.searchForm.timeRange[0]+" "+"00:00:00":"",
        EndTime: this.searchForm.timeRange[1]?this.searchForm.timeRange[1]+" "+"23:59:59":"",
        Sorting: this.searchForm.sorting,
        SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        MaxResultCount: this.searchForm.maxResultCount
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData = res.data.items
          this.tableDataTotal = res.data.totalCount
        }
        this.disabledDown=false;
        this.loading = false;
      })
    },
    downLoad(){
      // console.log("導出");
      this.disabledDown=true;
      let params={
        Name: this.searchForm.name,
        LastName: this.searchForm.lastname,
        RecommendCode: this.searchForm.code,
        Startime: this.searchForm.timeRange[0]?this.searchForm.timeRange[0]+" "+"00:00:00":"",
        EndTime: this.searchForm.timeRange[1]?this.searchForm.timeRange[1]+" "+"23:59:59":"",
        Sorting: this.searchForm.sorting,
        SkipCount: (this.currentPage - 1) * this.searchForm.maxResultCount,
        MaxResultCount: this.searchForm.maxResultCount
      }
      for (let key in params){
          if(params[key]===""){
              delete params[key];
          }
      }
      this.loading=true;
      api.memberExportReport(params).then(res=>{
        if(res.systemCode===200){
            window.open(res.data,"_blank")
        }
        this.disabledDown=false;
        this.loading=false;
      })
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
.privacylist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .w_50{
    width: 50%;
  }
  .addbtn {
    width: 100px;
    float: right;
  }

  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
